<template>
  <div class="work">
    <div class="container is-max-desktop">
      <div class="py-6">
        <p class="title is-1 is-size-3-mobile has-text-centered has-text-black">
          HelloPass - An internal H5 App of HelloChat
        </p>
        <nav
          class="breadcrumb is-small is-centered"
          aria-label="breadcrumbs"
        >
          <ul>
            <li><a @click="$router.push('/')">features</a></li>
            <!-- <li><a href="/work">work</a></li> -->
            <li class="is-active">
              <a href="#" aria-current="page">HelloPass</a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- <div class="markdown-body">
        <Demo />
      </div> -->
    </div>

    <!-- Cover Image -->
    <div class="container is-max-widescreen">
      <figure class="image">
        <img src="img/works/087/cover-1.jpg" />
      </figure>
    </div>

    <div class="my-6" />

    <!-- Product Overview -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 is-size-3-mobile has-text-weight-bold has-text-black">
          Project Overview
        </p>
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Product Description
              </p>
              <div class="content">
                <p>
                  HelloPass is an internal H5 App of HelloChat App’s new feature
                  which provides a new way for users to discover and experience
                  the online events easily.
                </p>
              </div>
            </article>
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Responsibility
              </p>
              <div class="content">
                <p>
                  I was responsible for UI and UX design of the app, including
                  user flows, sketching ideas, wireframes, low-and high-fidelity
                  prototypes, usability testing, and research base on product
                  requirements
                </p>
              </div>
            </article>
          </div>
          <div class="tile is-vertical">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Goal
                  </p>
                  <div class="content">
                    <p>Let users to discover online events easily.</p>
                  </div>
                </article>
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Role
                  </p>
                  <div class="content">
                    <p>UI/UX designer, Front-end CSS designer</p>
                  </div>
                </article>
              </div>
              <div class="tile is-parent is-vertical">
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Duration
                  </p>
                  <div class="content">
                    <p>1.5 Month</p>
                  </div>
                </article>
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Tools
                  </p>
                  <div class="content">
                    <p>Figma, Xmind, VScode</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-6" />

    <!-- Design -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 is-size-3-mobile has-text-weight-bold has-text-black">
          Product Design
        </p>
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                User Interface Design & Core Flow
              </p>
            </article>
          </div>
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <div class="content">
                <p>
                  As an internal sub-service app, it is critical to allow users
                  to seamlessly experience a simplified ticketing experience. As
                  an internal sub-service, it is critical to allow users to
                  seamlessly experience a simplified ticketing experience;
                  therefore, we need to ensure the following points while
                  maintaining operational smoothness as much as possible,
                </p>
                <ol class="skill">
                  <li>Clear UI layout</li>
                  <li>Rich and organized information</li>
                  <li>Timely user action notices and feedbacks</li>
                </ol>
              </div>
              <!-- <figure class="image" @click="handleOpenActiveLightBox('https://dummyimage.com/16:9x1080/f8f8f8/b0b0b0')">
                <img src="https://dummyimage.com/16:9x1080/f8f8f8/b0b0b0" />
              </figure>
              <p class="subtitle is-7 has-text-centered py-3">Click on image to enlarge</p> -->
            </article>
          </div>
        </div>

        <div class="my-6" />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/087/ui-1.jpg')"
              >
                <img src="img/works/087/ui-1.jpg" />
              </figure>
              <!-- <p class="subtitle is-7 has-text-centered py-3">
                Click on image to enlarge
              </p> -->
            </article>
          </div>
        </div>

        <div class="my-6" />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                More User Interface Design
              </p>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/087/ui-2.jpg')"
              >
                <img src="img/works/087/ui-2.jpg" />
              </figure>
              <!-- <p class="subtitle is-7 has-text-centered py-3">
                Click on image to enlarge
              </p> -->
            </article>
          </div>
        </div>
      </div>
    </div>

    <!-- Understanding Users -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 is-size-3-mobile has-text-weight-bold has-text-black">
          Front-end CSS coding
        </p>
        <hr />

        <div class="my-6" />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                Implement app UI style design & coding
              </p>
            </article>
            <article class="tile is-child">
              <p>
                In this project, in addition to completing the APP UI design, I
                also participated in the coding work of implementing the
                application UI style; mainly completed the following four parts,
              </p>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/087/dev-1.jpg')"
              >
                <img src="img/works/087/dev-1.jpg" />
              </figure>
              <!-- <p class="subtitle is-7 has-text-centered py-3">
                Click on image to enlarge
              </p> -->
            </article>
          </div>
        </div>
      </div>
    </div>

    <!-- Image light box modal -->
    <div class="modal" :class="{ 'is-active': isLightBoxActive }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <figure class="image">
          <img :src="lightBoxImageSrc" />
        </figure>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="handleCloseActiveLightBox"
      ></button>
    </div>

    <!-- Back to top -->
    <back-to-top bottom="50px" right="50px">
      <button class="button is-normal is-responsive">
        <span class="icon has-text-weight-bold has-text-black">UP</span>
      </button>
    </back-to-top>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
// import VueMarkdown from 'vue-markdown-render';
// import Demo from "./demo.md";

// 代码高亮
// import "highlight.js/styles/github.css";
// 其他元素使用 github 的样式
// import "github-markdown-css";

export default {
  name: "work",
  components: {
    Navbar,
  },
  data() {
    return {
      isLightBoxActive: false,
      lightBoxImageSrc: "",
    };
  },
  mounted() {
    this.track();
  },
  methods: {
    handleOpenActiveLightBox(src) {
      this.lightBoxImageSrc = src;
      this.isLightBoxActive = true;
    },
    handleCloseActiveLightBox() {
      this.lightBoxImageSrc = "";
      this.isLightBoxActive = false;
    },
    toIndexList() {
      // this.$router.push('/');
      // this.$router.reload();
      window.location.reload();
    },
    track() {
      this.$gtag.pageview("/hellopass");
      this.$gtag.query('event', 'screen_view',{
        app_name: 'juliandesign.io',
        screen_name: 'HelloPass',
      })
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 769px) {
  .modal-content,
  .modal-card {
    width: 80%;
  }
}
</style>
